<template>
  <div class="page-add-or-edit-asset">
    <b-card>
      <div class="title-page-asset">
        <h4>{{isUpdate? 'Cập nhật' : 'Tạo mới'}} tài sản</h4>
      </div>
      <div class="main-content-page">
        <validation-observer ref="modelAsset">
          <div class="form-asset">
            <b-row>
              <b-col lg="3" md="3" sm="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="departmentId" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Phòng ban <span class="text-danger">(*)</span></label>
                    <v-select label="organizationBranchName" v-model="modelAsset.organizationBranchId" :options="listDepartment" :reduce="(department) => department.id"></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="nameDevice" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Tên tài sản <span class="text-danger">(*)</span></label>
                    <b-form-input v-model="modelAsset.type" placeholder="Nhập tên tài sản"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="3" xs="12">
                <b-form-group>
                  <label class="font-weight-bold font-size-label-custom">Nhà cung cấp</label>
                  <b-form-input v-model="modelAsset.supplier" placeholder="Nhập tên nhà cung cấp"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" sm="3" xs="12">
                <b-form-group>
                  <label class="font-weight-bold font-size-label-custom">Hãng</label>
                  <b-form-input v-model="modelAsset.brand" placeholder="Nhập tên hãng"></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="form-asset">
            <b-row>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="codeDevice" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Mã <span class="text-danger">(*)</span></label>
                    <b-form-input v-model="modelAsset.model" placeholder="Nhập mã"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="numberSerial" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Số serial <span class="text-danger">(*)</span></label>
                    <b-form-input v-model="modelAsset.serialNumber" placeholder="Nhập serial"></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="dateDevice" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Ngày nhập <span class="text-danger">(*)</span></label>
                    <flat-pickr v-model="modelAsset.importDate"
                                class="form-control"
                                :config="configFlatpickr"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col lg="3" md="3" xs="12">
                <b-form-group>
                  <validation-provider #default="{ errors }" name="statusAsset" rules="required">
                    <label class="font-weight-bold font-size-label-custom">Trạng thái <span class="text-danger">(*)</span></label>
                    <v-select v-model="modelAsset.status" :reduce="(valType) => valType.value" label="name" :options="listTypeAsset"></v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="form-asset">
            <b-row>
              <b-col lg="12" md="12" xs="12">
                <b-form-group>
                  <label class="font-weight-bold font-size-label-custom">Thông tin thêm</label>
                  <b-form-textarea v-model="modelAsset.metadata"></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="acction-add-or-edit">
            <b-button v-if="!isUpdate" class="mr-1" @click="addAsset"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
            >
              <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
              />
              <span class="align-middle">Tạo mới</span>
            </b-button>
            <b-button v-if="isUpdate" class="mr-1" @click="updateAsset"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
            >
              <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
              />
              <span class="align-middle">Cập nhật</span>
            </b-button>
            <b-button :to="{ name: 'manager-asset'}"
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-danger"
            >
              <feather-icon
                  icon="CornerDownLeftIcon"
                  class="mr-50"
              />
              <span class="align-middle">Quay lại</span>
            </b-button>
          </div>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BButton,
  VBTooltip, BFormGroup, BRow, BCol, BFormInput, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import useJwt from '@/auth/jwt/useJwt'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import * as moment from 'moment'
import { required } from '@validations'

export default {
  components: {
    BCard,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
    flatPickr,
    BButton,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      isUpdate: false,
      listDepartment: [],
      idAsset: null,
      listTypeAsset: [
        {
          id: 1,
          name: 'Hoạt động',
          value: 0,
        },
        {
          id: 2,
          name: 'Sửa chữa',
          value: 1,
        },
        {
          id: 3,
          name: 'Hỏng',
          value: 2,
        },
      ],
      required,
      modelAsset: {
        brand: '',
        importDate: '',
        metadata: '',
        model: '',
        organizationBranchId: null,
        serialNumber: null,
        supplier: '',
        totalQuantity: 1,
        type: '',
        deviceType: 0,
        status: null,
      },
      configFlatpickr: {
        wrap: true,
        altFormat: 'd/m/Y',
        altInput: true,
        locale: Vietnamese,
        allowInput: true,
      },
    }
  },
  created() {
    const parameters = this.$route.query
    this.idAsset = parameters.id
    if (this.idAsset) {
      this.isUpdate = true
      this.getDetailAsset()
    }
    this.getListAllDepartment()
  },
  methods: {
    getListAllDepartment() {
      this.$crm.get('organization-branch-school/find-all-department').then(res => {
        this.listDepartment = res.data
      })
    },
    addAsset() {
      this.$refs.modelAsset.validate().then(success => {
        if (success) {
          if (this.modelAsset.importDate) {
            this.modelAsset.importDate = moment(this.modelAsset.importDate).format('yyyy-MM-DDT00:00:00')
          }
          this.$crm.post(`device/create/${this.modelAsset.organizationBranchId}`, this.modelAsset).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Tạo tài sản thành công',
              },
            })
            this.$router.push({
              name: 'manager-asset',
            })
          })
        }
      })
    },
    updateAsset() {
      this.$refs.modelAsset.validate().then(success => {
        if (success) {
          this.$crm.post(`device/update/${this.idAsset}`, this.modelAsset).then(res => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Thành công!',
                icon: 'CheckIcon',
                variant: 'success',
                text: `Cập nhật tài sản ${this.modelAsset.type} thành công`,
              },
            })
            this.$router.push({
              name: 'manager-asset',
            })
          })
        }
      })
    },
    getDetailAsset() {
      this.$crm.post(`device/find-by-id/${this.idAsset}`).then(res => {
        this.modelAsset = res.data
      })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
@import '../../@core/scss/custom/add-or-edit-asset';
@import 'src/@core/scss/vue/libs/vue-flatpicker.scss';
</style>
